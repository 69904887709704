.underline {
    text-decoration: underline;
}

.events-map {
    width: 100%;
    height: 500px;
}

.schedule-panel-row {
    font-size: x-large;
    width: 94%;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-left: 3%;
    padding: 10px;
    display: inline-block;
    background-color: #f0f0f0;
}

.schedule-panel-left {
    width: 20%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.schedule-panel-div {
    width: 30%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.schedule-panel-right {
    width: 30%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.scheduleContentTable {
    /* border: 1px solid black;  */
    /* display: inline-block;*/
    width: 100%;
    border-collapse: collapse;
    /* Убираем двойные линии */
}

.imageGallerySection {

    max-width: 500px;
    height: auto;
    /* height:350px; */
    margin-left: 50%;
    display: block;
    margin: auto auto;
}

/* .imageGalleryClass{
     width:500px;
     height:350px; 
} */

.event-section-schedule {
    margin-top: 10px;
    width: 100%;
}

.event-section-info {
    width: 50%;
    vertical-align: top;
}

/* .firstDiv{
    padding-top:10px;
    width:50%;
    float: left;
    padding-bottom:100px;
}
.secondDiv{
    width:50%;
    float: left;
    
} */

.padding-left-10 {
    padding-left: 10px;
}

.padding-right-10 {
    padding-right: 10px;
}

.text-small {
    font-size: small;
}

.scheduleContentTable tbody tr:nth-child(even) {
    background: #f0f0f0;
    /* Зебра */
}

.event-deleted {
    text-decoration: line-through;
    color: orangered;
}

.text-red{
    color: red;
}

.scheduleContentTable thead {
    background: #f5e8d0;
    /* Цвет фона заголовка */
}

.scheduleContentTable td {
    padding: 5px;
    /* Поля в ячейках */

    border: 1px solid #333;
    /* Параметры рамки */
}

@media (max-width: 600px) {
    body {
        font-size: 80%;
    }

    .event-section-schedule {
        padding-top: 10px;
        width: 100%;
    }

    .PanelHeader__content {
        overflow: unset;
    }
    .event-section-info {
        width: 100%;
    }
}