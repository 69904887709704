body{
    font-family: -apple-system,BlinkMacSystemFont,'Roboto','Helvetica Neue',Geneva,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai",sans-serif,arial,Tahoma,verdana;
}

.text-success{
    color:green;
}

.text-warning{
    color:yellow;
}

.text-danger{
    color:red;
}

.info-section{
    margin-left: 10px;
    margin-right: 10px;
    margin-top:10px;
}

.events-map{
    width:100%;
    height:500px;
}

.padding-left-2p{
    padding-left:2%;
}

.padding-left-3p{
    padding-left:3%;
}

.padding-top-12{
    padding-top:12px;
    
}

.testclass{
    border: 1px solid black;
}

.middle-section{
height:auto;
}

.edit-event-button{
    margin-bottom:10px;
}

.warning-cell{
    color: orange;
}

.link{
    cursor: pointer;
    color:blue;
    text-decoration: underline;
}

.cancel-link{
    cursor: pointer;
    color:red;
    text-decoration: underline;
}

.request-button{
    background-color: orangered;
}

.margin-top-10{
    margin-top: 10px;
}

.vertical-center{
    vertical-align: auto;
}

.vertical-top{
    vertical-align: text-top;
}

.text-top{
    vertical-align: top;
}



.flex-row{
    flex-direction: row;
}

.float-left{
    float: left;
}

.width-50p{
    width: 50%;
}

.width-auto{
    width: auto;
}

.inline-block{
    display: inline-block;
}

.centered{
    display: block;
    margin: auto auto;
 }

.flex-centered{
    display: flex;
    justify-content: center;
}

 .block-sm{
    width:25%;
 }

 .list-item{
     padding-bottom: 10px;
     padding-left:20px;
     
 }

 .margin-40pr{
     margin: 0 40%;
 }

 .text-center{
     text-align: center;
 }

 .iframe-video{
     width:500px;
     height:350px;
 }

 .pointer{
     cursor: pointer;
 }

 .headerIcon {
    padding-right: 5px;
}

 .bottom{
    position: relative;
    margin-top:25%;
 }

 .img-wrap {
    position: relative;
}

.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
}

/* .panel-base{
     background-color: #edeef0; 
    
} */

.panel-body{
    margin:25px 25px 25px 25px;
    background-color: white;
}

.page-block{
    box-shadow: 0 1px 0 0 var(--steel_gray_120),0 0 0 1px var(--steel_gray_80);
}

.login-section{
    width:150px;
    padding:25px;
    /* background-color: cornflowerblue; */
}

.login-element{
    padding-bottom:10px;
    width: 100%;
}

.notification-box{
    background: #e3f7fc ;
    border: 1px solid #f5f5f5;
    margin-bottom: 10px;
    border-radius: 5px;
}

.collapse-button{
    border-bottom:1px solid rgb(196, 196, 196);
    background:rgb(218, 218, 218);
}

.collapse-button:hover{
    background:rgb(218, 218, 218);
    padding-left:10px;
}

  .collapse-section {
    margin-left: 20px;
    margin-top:20px;
    height: auto;
    overflow: hidden;
    transition: height 400ms linear;
}

.cell-onhover:hover{
background-color: #f0f0f0;
}

.schedule-table{
    width:100%;
    border-spacing: 0px;
  border-collapse: collapse;
}

.schedule-table-header{
    text-align: center;
    font-size:18px;
    padding-bottom: 10px;
    font-weight: bold;
}

.schedule-table tbody tr:nth-child(even) {
    background: #f0f0f0; /* Зебра */
   }


   .schedule-table thead{
    background: #f0f0f0; /* Параметры рамки */
    
   }

   .schedule-table th{
    border-bottom: 2px solid #6678b1;
   }

   .border-bottom{
    border-bottom: 1px solid #6678b1;
   }


    .schedule-table thead:last-child {
        background: #ffffff;
    }

.hidden{
    content: "-";
    background:rgb(230, 230, 230);
}

.hidden ~ .collapse-section{
    height: 0px;
    margin-top:0px;
}

.collapseContainer {
    height: 0;
    overflow: hidden;
    transition: height 400ms linear;
}

.element-hidden{
    display:none;
}

.toggle {
    display: none;
}

.toggle:checked ~ .collapseContainer {
    height: 50px;
}


/* .notification-box:before{
    content: 'i';
    position: absolute; top: 50%; left: 0;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #f00;
    color: #fff;    
} */

.notification-box-text-section{
    display: inline-block;
    position:relative;
    width:80%;
}

.notification-box-icon-section{
    display: inline-block;
    position:relative;
    margin:auto 0;
    width:20%;
    color:#ff6207;
}

.notification-box-header{
    padding: 10px 0px 5px 10px; /* Поля вокруг текста */
    
font-size: x-small;
}


.notification-box-body{
    padding: 5px 0px 10px 20px;
    font-size: larger;
}


.tn-box {
    width: 360px;
    position: relative;
    margin: 0 auto 20px auto;
    padding: 25px 15px;
    text-align: left;
    border-radius: 5px;
    box-shadow:
    0 1px 1px rgba(0,0,0,0.1),
    inset 0 1px 0 rgba(255,255,255,0.6);
    opacity: 0;
    cursor: default;
    display: none;
    }
     
    .tn-box-color-1{
    background: #ffe691;
    border: 1px solid #f6db7b;
    }

 /* @media (max-width: 750px) {
    body {
        font-size:80%;
    }

    .iframe-video{
        width:auto;
        height:auto;
    }
 
} */